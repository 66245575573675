<template>
    <div>
        <v-toolbar flat>
            <!-- <v-toolbar-title>{{ $t('title.error_user_list') }}</v-toolbar-title> -->
            <v-col cols="6" sm="6" md="4" class="text-right pl-5">
                <v-select
                v-model="module_id"
                :items="listModule"
                :label="$t('input.select_module')"
                item-text="name"
                item-value="_id"
                @input="selectModule(module_id)"
                ></v-select>
            </v-col>
            <v-col cols="6" sm="6" md="4" class="text-right pl-5">
                <v-select
                  v-model="channelType"
                  :items="items"
                  item-value="id" item-text="name"
                  label="Channel #  "
                  class="channel-type   "
                  style="min-width:100%"
                  @input="selectChannelType(channelType)"
                  solo
              ><template v-slot:prepend>
                <v-icon color="green darken-2">mdi-television</v-icon>
              </template>
              </v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-text-field v-model="params.search"
                          append-icon="mdi-magnify"
                          :label="$t('input.search')"
                          single-line
                          hide-details
                          clearable
                          @input="fetchListSyncModule(params)"
                          class="mr-5" />
            <!-- <v-btn :loading="loading" :disabled="loading" class="ml-5" color="primary" @click="_refreshAllSyncData()">
                {{ $t('button.sync_all') }}
            </v-btn> -->
        </v-toolbar>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        props: ["darkmode", "status", "params", "dataDelete", "userPermission","listModule"],
        data: () => ({
            getLoadingDetail:false,
            detailData:[],
            detailDataCount:[],
            dialog: false,
            loading: false,
            module_id: process.env.VUE_APP_COCKFIGHT,
            channelType:  1,
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ["user_name"],
                search: '',
                channelType:  1,
                module_id: process.env.VUE_APP_COCKFIGHT
            },
            items: [
                {name:"CH01", id:1},
                {name:"CH02", id:2},
                {name:"CH03", id:3},
                {name:"CH04", id:4},
                {name:"CH05", id:5},
                {name:"CH06", id:6},
            ],
        }),
        methods: {
            // _refreshAllSyncData(){
            //     this.loadRefreshAllSyncData();
            // },
            // async loadRefreshAllSyncData(){
            //     this.loading = true;
            //     await this.$request
            //         .post({
            //         url: "sync-module/refreshAllSyncData",
            //         data: {},
            //         })
            //         .then((res) => {
            //         if (res.data) {
            //             // console.log(res.data);
            //             this.loading = false;
            //             window.location.reload();
            //         }else{
                        
            //             this.getLoadingDetail = false;
            //         }
            //         });
            // },

            selectChannelType(channel_type){
                
                this.data.module_id = this.module_id;
                this.data.channel_type = channel_type;
               
                this.fetchListErrorUserList(this.data).then(() => {
                        let date = {
                         module_id: this.module_id,
                         channel_type: channel_type
                        }
                    this.$emit("child-checkbox", date);
                    })
                // this.$router.go();
            },
            selectModule(module_id){
                
                this.data.module_id = module_id;
               
                this.fetchListErrorUserList(this.data).then(() => {
                        let date = {
                         module_id: this.module_id,
                         channel_type: this.channelType
                        }
                    this.$emit("child-checkbox", date);
                    })
                // this.$router.go();
            },
            ...mapActions("$_errorUserList", ["fetchPopUp", "fetchListErrorUserList", "deleteModule"]),
        },
    };
</script>

<style lang="scss" scoped>
</style>
